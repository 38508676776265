function setEqualHeight() {
    $(".grid-menu-item-022-top .menu-item-row-element.img").matchHeight();
    $(".grid-menu-item-022-top .menu-item-title").matchHeight();
    $(".grid-menu-item-022-top .content-container > .menu-item-content").matchHeight();
}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').on("change",function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $menu.css("max-height",maxHeight + "px");
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $menu.css("max-height","");
            $menu.css("height","");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 110,
        initial_height = 110,
        target_height = 70,
        initial_padding = 20,
        target_padding = 5,
        margin,
        logo_height,
        padding,
        line_height;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit = 110;
            initial_height = 110;
            target_height = 70;
            initial_padding = 20;
            target_padding = 5;
            break;
        case "xxlarge":
        scroll_limit = 110;
        initial_height = 110;
        target_height = 70;
        initial_padding = 20;
        target_padding = 5;
            break;
        case "large":
        scroll_limit = 110;
        initial_height = 110;
        target_height = 70;
        initial_padding = 20;
        target_padding = 5;
            break;
        case "medium":
        scroll_limit = 110;
        initial_height = 110;
        target_height = 70;
        initial_padding = 20;
        target_padding = 5;
            break;
        case "small":
        scroll_limit = 110;
        initial_height = 110;
        target_height = 70;
        initial_padding = 20;
        target_padding = 5;
            break;
        case "xsmall":
        scroll_limit = 110;
        initial_height = 110;
        target_height = 70;
        initial_padding = 20;
        target_padding = 5;
            break;
        default:
        scroll_limit = 110;
        initial_height = 110;
        target_height = 70;
        initial_padding = 20;
        target_padding = 5;
            break;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() === 0) {
            padding = initial_padding;
            logo_height = initial_height;
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit) {
            padding = initial_padding - (initial_padding - target_padding) * ($(window).scrollTop() / scroll_limit);
            logo_height = initial_height - (initial_height - target_height) * ($(window).scrollTop() / scroll_limit);
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").show();
                $("#logo-scroll").hide();
            }
            */
        } else {
            padding = target_padding;
            logo_height = target_height;
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").hide();
                $("#logo-scroll").show();
            }
            */
        }
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        $("#logo a").css("height", logo_height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});
